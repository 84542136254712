// @flow
import React from 'react';
import _ from 'lodash/fp';
import getDisplayName from '@graphite/get-display-name';
import styled from '@emotion/styled';
import type { TId, TWidgetMode, TWidgetDiff } from '@graphite/types';
import type { TWidgetButton } from '../constants/types';

type TMinimalProps = $ReadOnly<{
	id: TId,
	widgetMode: TWidgetMode,
	data: TWidgetButton,
	editWidget: (
		targetId: TId,
		instanceId: ?TId,
		originId: TId,
		diff: TWidgetDiff,
	) => void,
	instanceId: ?TId,
	originId: ?TId,
}>;

const SpanEditable = styled.span`
	outline: none;
	border: none;

	&[contentEditable='false'] {
		user-select: none;
	}
	&:empty {
		/* чтобы курсор было видно */
		padding-left: 1px;
	}
`;

const DELAY_SAVE = 1e3;

const withEditable = <T: TMinimalProps>(
	Component: React$ComponentType<T>,
): React$ComponentType<$ReadOnly<{ ...$Exact<T> }>> => {
	const Editable = (props: T, ref) => {
		const { id, data, widgetMode, editWidget, instanceId, originId } = props;
		const { text = { isShown: false } } = data;

		const editableRef = React.useRef();

		const prevWidgetMode = React.useRef(widgetMode);
		React.useEffect(() => {
			// При входе в режим редактирования селектим весь текст
			if (
				prevWidgetMode.current !== widgetMode &&
				widgetMode === 'widget-edit' &&
				editableRef.current
			) {
				const el: HTMLElement = editableRef.current;
				const documentFrame = el.ownerDocument;
				const windowFrame = documentFrame.defaultView;
				const selection = windowFrame.getSelection();
				const range = documentFrame.createRange();
				range.selectNodeContents(el);
				selection.removeAllRanges();
				selection.addRange(range);
				el.focus();
			}

			prevWidgetMode.current = widgetMode;
		}, [widgetMode]);

		const save = React.useMemo(
			() =>
				_.debounce(DELAY_SAVE, (label = '') => {
					if (originId)
						editWidget(id, instanceId, originId, {
							text: {
								isShown: (text && text.isShown) || false,
								label,
							},
						});
				}),
			[id, editWidget, instanceId, originId, text],
		);

		const onInputHandler = React.useCallback(
			(e: KeyboardEvent) => {
				e.stopPropagation();

				if (
					e.target instanceof
					editableRef.current?.ownerDocument.defaultView.Node
				) {
					const text = e.target.innerText;
					save(text);
				}
			},
			[save],
		);

		const onKeyPressHandler = React.useCallback((e: KeyboardEvent) => {
			if (e.keyCode === 13) {
				e.preventDefault();
			}
		}, []);

		const onPasteHandler = React.useCallback(e => {
			// cancel paste
			e.preventDefault();

			if (editableRef.current) {
				const el: HTMLElement = editableRef.current;
				const documentFrame = el.ownerDocument;

				// get text representation of clipboard
				const text = (e.originalEvent || e).clipboardData.getData('text/plain');

				// insert text manually
				documentFrame.execCommand('insertHTML', false, text);
			}
		}, []);

		return (
			// eslint-disable-next-line react/jsx-props-no-spreading
			<Component {...props} ref={ref}>
				{text && text.isShown ? (
					<SpanEditable
						contentEditable={widgetMode === 'widget-edit'}
						spellcheck="false"
						onInput={onInputHandler}
						onKeyUp={onInputHandler}
						onKeyDown={onInputHandler}
						onChange={onInputHandler}
						onKeyPress={onKeyPressHandler}
						onPaste={onPasteHandler}
						ref={editableRef}
						dangerouslySetInnerHTML={{ __html: text.label }}
					/>
				) : null}
			</Component>
		);
	};

	Editable.displayName = `withFontsEdit(${getDisplayName(Component)})`;

	return React.memo<T>(React.forwardRef(Editable));
};

export default withEditable;
